$(document).ready(function() {
    common();
    jumpMenu();
    bannerSlider();
    // productDetailSlider();
    goToTop();
    question();
    blogSharing();
    owlNews();
    // productSharing();
    // activitySharing();
    loadGoogleFont();
    bgImagesLazyload();
});

function jumpMenu() {

    //選單跳段落
    $('.nav a').click(function() {

        console.log('click menu link');

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
            jumpSection(this.hash);
        }
        // return false;
    });

    //處理編輯器要跳段落
    $('.editor a').click(function() {
        var $anchor = $(this);
        console.log('click editor internal link');
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {

            if ($anchor.attr('href').indexOf('http') < 0) {
                //不是完整連結才要跳
                jumpSection(this.hash);
                // return false;
            }

        }
    });

    //關閉手機選單
    $('body').click(function(event) {
        // only do this if navigation is visible, otherwise you see jump in navigation while collapse() is called 
        if ($('.navbar-collapse').is(':visible') && $('.navbar-toggle').is(':visible')) {
            $('.navbar-collapse').collapse('toggle');
        }
    });

    /*才能避免slider產生後的高度讓跳cut不正確*/
    var jumpHash = location.hash;
    //超連結跳段落
    if (jumpHash !== '') {
        var newjumpHash = jumpHash.replace(/\//, '');
        console.log('detect jump from link url:' + newjumpHash);
        Pace.on('done', function() {
            jumpSection(newjumpHash);
        });
    }
}


function bannerSlider() {
    var bSlider = null;
    // Pace.on('done', function() {
        var $bannerSlider = $('#bannerSlider');
        if ($bannerSlider.length > 0) {

            setMasterSliderImageOnScreen($bannerSlider);

            try {
                bSlider = new MasterSlider();
                // adds Arrows navigation control to the slider.

                bSlider.control('timebar', {
                    insertTo: '#bannerSlider'
                });
                bSlider.control('bullets');

                bSlider.control('circletimer', { autohide: false, overVideo: true, color: '#FFFFFF', radius: 4, stroke: 9 });

                bSlider.control('arrows', { autohide: true });
                bSlider.setup('bannerSlider', {
                    width: 1280, // slider standard width
                    height: 640, // slider standard height
                    // minHeight: 400,
                    start: 1,
                    space: 0,
                    layout: 'fullwidth',
                    loop: true,
                    preload: 0,
                    instantStartLayers: false,
                    autoplay: true,
                    overPause: true,
                    view: "fadeBasic"
                });
                $('.master-skeleton-loader').remove();
                // $(window).trigger('resize');

            } catch (err) {
                console.error(err);
                removeErrorMasterSliderContainer($bannerSlider);
            }
        }
    // });
}

// function productDetailSlider() {
//     var bSlider = null;
//     Pace.on('done', function() {
//         var $productSlider = $('#productSlider');
//         if ($productSlider.length > 0) {
//             try {

//                 bSlider = new MasterSlider();

//                 bSlider.control(
//                     'thumblist', {
//                         autohide: false,
//                         overVideo: true,
//                         dir: 'h',
//                         speed: 17,
//                         inset: false,
//                         arrows: false,
//                         hover: false,
//                         customClass: '',
//                         align: 'bottom',
//                         type: 'thumbs',
//                         margin: 5,
//                         width: 100,
//                         height: 100,
//                         space: 5,
//                         fillMode: 'fill'
//                     });

//                 bSlider.setup('productSlider', {
//                     width: 500,
//                     height: 500,
//                     space: 5,
//                     view: 'fadeBasic'
//                 });
//                 // $(window).trigger('resize');

//             } catch (err) {
//                 console.log(err);
//                 removeErrorMasterSliderContainer($productSlider);
//             }

//         }
//     });
// }

function goToTop() {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 300) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });

    //Click event to scroll to top
    $('.scrollToTop').click(function() {
        $('html, body').animate({ scrollTop: 0 }, 800);
        return false;
    });
}

function question() {

    if (window.JUMP_DIR != '') {
        var $q4Elem = $("#" + window.JUMP_DIR);
        if ($q4Elem.length > 0) {
            $('html, body').animate({ scrollTop: $q4Elem.offset().top }, 2000);

        }
    }

    var $questions =
        $('.questions');

    var $questionsTitle =
        $questions.find('li > h3');

    $questionsTitle.prepend('<i class="fa fa-caret-right" aria-hidden="true"></i> ');

    $questionsTitle.click(function(e) {

        var $expand = $(this).find('i');
        // console.log($expand);
        if ($expand.hasClass('fa-caret-right')) {
            //open
            var $answer =
                $(this).next();
            $answer.show();

            $expand.removeClass('fa-caret-right')
                .addClass('fa-caret-down');
        } else {
            //close
            var $answer =
                $(this).next();
            $answer.hide();

            $expand.removeClass('fa-caret-down')
                .addClass('fa-caret-right');
        }

    });

    var $answers =
        $questions.find('li > div');
    $answers.hide();

}

function blogSharing() {
    initJsSocial($('#blogSharing'));
}

function initJsSocial($targetElem) {
    if ($targetElem.length > 0) {
        $targetElem.jsSocials({
            shares: ["twitter", "facebook", "linkedin"]
        });
    }
}

function owlNews() {
    var $owlNews = $('.owl-news');
    Pace.on('done', function() {
        owlSlider(
            $owlNews,
            1,
            1, {
                0: {
                    items: 1
                },
                600: {
                    items: 4
                },
                1000: {
                    items: 4
                }
            });

        $(".owl-news-next").click(function() {
            console.log('owl news next');
            $owlNews.trigger('next.owl');
        })

        $(".owl-news-prev").click(function() {
            console.log('owl news prev');
            $owlNews.trigger('prev.owl');
        })
    });
}


function owlSlider($owlSlider, items, slideBy, responsive) {
    console.log('owlSlider:');
    var owlInstance;


    if ($owlSlider.length > 0) {
        var opts = {
            loop: true,
            center: true,
            margin: 10,
            nav: true,
            items:items,
            slideBy: slideBy
        }

        if (typeof(responsive) != 'undefined') {
            opts.responsive = responsive;
        }
        $owlSlider.owlCarousel(opts);

        var $owlImages = $owlSlider.find('img');

        $owlImages.on("inview", function(event, isInView) {
            if (isInView) {

                var $img = $(this);
                // console.log($img);
                if ($img.attr("data-lazy") != 'undefined') {

                    $img.attr("src", $img.attr("data-lazy"));
                    $img.removeAttr("data-lazy");
                }

            }
        });

    }

}


function bgImagesLazyload() {
    initLazyload($('.bg-snow'));
}


function loadGoogleFont() {
    Pace.on('done', function() {
        WebFont.load({
            timeout: 2000,
            google: {
                families: ['Noto Sans TC&display=swap', 'Open Sans&display=swap']
            }
        });
    });
  
}